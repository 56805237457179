import { render, staticRenderFns } from "./ExtentionObjectGroup.vue?vue&type=template&id=b8ca65a4&scoped=true&"
import script from "./ExtentionObjectGroup.vue?vue&type=script&lang=js&"
export * from "./ExtentionObjectGroup.vue?vue&type=script&lang=js&"
import style0 from "./ExtentionObjectGroup.vue?vue&type=style&index=0&id=b8ca65a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ca65a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chenzihao/code/workspace/arkui-bus/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8ca65a4')) {
      api.createRecord('b8ca65a4', component.options)
    } else {
      api.reload('b8ca65a4', component.options)
    }
    module.hot.accept("./ExtentionObjectGroup.vue?vue&type=template&id=b8ca65a4&scoped=true&", function () {
      api.rerender('b8ca65a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/extension-property/ExtentionObjectGroup.vue"
export default component.exports