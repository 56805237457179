var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      {
        class: _vm.cssFeatures.hover,
        staticStyle: { "text-decoration": "underline", color: "#0F8EE9" },
        attrs: {
          "data-target-tag": "customerUrlText",
          title: _vm.params.value || ""
        }
      },
      [_vm._v(_vm._s(_vm.params.value || ""))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }