var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "description" }, [
    _c("div", { staticStyle: { flex: "1" } }, [
      _c("span", { staticClass: "fieldName" }, [
        _vm._v("\n      " + _vm._s(_vm.option.name) + " ：\n    ")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "content" }, [
        _vm._v("\n      " + _vm._s(_vm.option.description) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: { display: "flex", "align-items": "center" },
        on: { click: _vm.removeOption }
      },
      [
        _c("Button", {
          attrs: {
            title: _vm.$t("extensionProperty.clearConfig"),
            icon: "iconbj_delete",
            type: "primary",
            shape: "circle"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }