var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.tags, function(item, index) {
        return [
          item.comments
            ? _c(
                "Poptip",
                {
                  key: item.webid,
                  attrs: {
                    trigger: "hover",
                    transfer: "",
                    "word-wrap": "",
                    width: "200",
                    content: item.comments
                  }
                },
                [
                  _c(
                    "span",
                    {
                      key: index,
                      class: [
                        item.cssclass,
                        "ak-ag-cell-tag",
                        "ak-ag-cell-tag-hover"
                      ]
                    },
                    [_vm._v(_vm._s(item.description))]
                  )
                ]
              )
            : _c(
                "span",
                { key: index, class: [item.cssclass, "ak-ag-cell-tag"] },
                [_vm._v(_vm._s(item.description))]
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }