var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "is-required": "",
                "row-label": _vm.$t("extensionProperty.filterBaseOnField"),
                "error-tip": _vm.validateTip
              }
            },
            [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("Input", {
                    attrs: {
                      placeholder: _vm.$t("extensionProperty.selectSourceField")
                    },
                    on: { "on-blur": _vm.validateInput },
                    model: {
                      value: _vm.formData.srccols,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "srccols", $$v)
                      },
                      expression: "formData.srccols"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("Input", {
                    attrs: {
                      placeholder: _vm.$t("extensionProperty.enterApi")
                    },
                    on: { "on-blur": _vm.validateInput },
                    model: {
                      value: _vm.formData.url,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "url", $$v)
                      },
                      expression: "formData.url"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }