var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { attrs: { title: _vm.params.value || "" } }, [
    _vm.data && _vm.data[_vm.columnName] && _vm.data[_vm.columnName].refobjid
      ? _c("span", { domProps: { innerHTML: _vm._s(_vm.params.value) } })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }