var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "radioItemWrapper", staticClass: "radioItemWrapper" },
    [
      _vm._l(_vm.option.enumerateValue, function(radio, index) {
        return _c(
          "label",
          {
            key: index,
            staticClass: "radioItem",
            attrs: { for: _vm.option.key + "-" + _vm.guid + "-" + index }
          },
          [
            _c("input", {
              attrs: {
                id: _vm.option.key + "-" + _vm.guid + "-" + index,
                type: "radio",
                name: _vm.option.key + "-" + _vm.guid
              },
              domProps: { value: radio.value },
              on: {
                change: function($event) {
                  return _vm.radioValueChange(radio.value)
                }
              }
            }),
            _vm._v("\n    " + _vm._s(radio.text) + "\n  ")
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "radioItem",
          attrs: { for: _vm.option.key + "-" + _vm.guid + "-clean" }
        },
        [
          _c("input", {
            attrs: {
              id: _vm.option.key + "-" + _vm.guid + "-clean",
              type: "radio",
              name: _vm.option.key + "-" + _vm.guid
            },
            domProps: { value: "__empty__" },
            on: {
              change: function($event) {
                return _vm.radioValueChange("")
              }
            }
          }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("extensionProperty.clearConfig")) +
                "\n    "
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }