var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "row-label": _vm.$t("extensionProperty.targetFieldEditable")
              }
            },
            [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("R3Radio", {
                    attrs: { options: _vm.disabledConfig },
                    on: {
                      change: function($event) {
                        return _vm.getRadioValue($event, _vm.disabledConfig.key)
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "row-label": _vm.$t("extensionProperty.ifFieldRequired"),
                "error-tip": _vm.radioValidateTip
              }
            },
            [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("R3Radio", {
                    attrs: { options: _vm.requiredConfig },
                    on: {
                      change: function($event) {
                        return _vm.getRadioValue($event, _vm.requiredConfig.key)
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.formData.field, function(keyInfo, index) {
            return _c(
              "ExtentionRowItem",
              {
                key: index,
                attrs: {
                  "row-index": index,
                  "row-count": _vm.formData.field.length,
                  "row-label": _vm.$t("extensionProperty.sourceField"),
                  "show-operation-button": "",
                  "is-required": "",
                  "error-tip": _vm.fieldValidateTip[index]
                },
                on: {
                  addRow: _vm.addRow,
                  removeRow: function($event) {
                    return _vm.removeRow(index)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("DropDownSelectFilter", {
                      attrs: {
                        single: "",
                        data: _vm.dropList,
                        "auto-data": _vm.searchList,
                        "page-size": _vm.pageSize,
                        "total-row-count": _vm.totalCount,
                        "columns-key": _vm.columnsKey,
                        hidecolumns: _vm.hidecolumns,
                        "default-selected":
                          _vm.defaultFields[keyInfo.refcolumn],
                        placeholder: _vm.$t("extensionProperty.selectField")
                      },
                      on: {
                        "on-popper-show": _vm.getSearchData,
                        "on-page-change": _vm.getSearchData,
                        "on-input-value-change": _vm.inputSearchData,
                        "on-fkrp-selected": function($event) {
                          return _vm.selectTable($event, index)
                        },
                        "on-clear": function($event) {
                          return _vm.clearTableName(index)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("Input", {
                      attrs: {
                        placeholder: _vm.$t("extensionProperty.enterValue")
                      },
                      on: { "on-blur": _vm.validateField },
                      model: {
                        value: _vm.formData.field[index].refval,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.field[index], "refval", $$v)
                        },
                        expression: "formData.field[index].refval"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }