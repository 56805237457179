var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-mt-10" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm.rowLabel
          ? _c(
              "div",
              { staticClass: "col row-title", class: [_vm.rowLabelClass] },
              [
                _c(
                  "span",
                  { class: [_vm.isRequired ? "required-remark" : ""] },
                  [_vm._v(_vm._s(_vm.rowLabel) + "：")]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
        _vm._v(" "),
        _vm.showOperationButton
          ? _c("div", { staticClass: "col btn-group" }, [
              _vm.rowCount - 1 === _vm.rowIndex
                ? _c(
                    "button",
                    {
                      staticClass: "operate-button",
                      on: { click: _vm.addRow }
                    },
                    [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.rowCount > 1
                ? _c(
                    "button",
                    {
                      staticClass: "operate-button",
                      on: { click: _vm.removeRow }
                    },
                    [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.errorTip
      ? _c("div", { staticClass: "error-tip tw-mt-10" }, [
          _c("div", {
            staticClass: "tw-inline-block row-title label-placeholder",
            class: [_vm.rowLabelClass]
          }),
          _vm._v("\n    " + _vm._s(_vm.errorTip) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }