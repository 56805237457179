var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extentionRadio" },
    [
      _c("Description", {
        attrs: { option: _vm.option },
        on: { removeOption: _vm.removeOption }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("EnumerateRadioItem", {
            attrs: { "default-data": _vm.defaultData, option: _vm.option },
            on: { radioValueChange: _vm.radioValueChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }