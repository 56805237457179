var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Description", {
        attrs: { option: _vm.option },
        on: { removeOption: _vm.removeOption }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("extensionProperty.leftColumnTable")))
          ]),
          _vm._v(" "),
          _vm._l(_vm.formData.leftPinnedColumns, function(keyInfo, index) {
            return _c(
              "ExtentionRowItem",
              {
                key: index,
                attrs: {
                  "row-index": index,
                  "row-count": _vm.formData.leftPinnedColumns.length,
                  "error-tip": _vm.fieldValidateTip.leftPinnedColumns[index],
                  "row-label": _vm.$t("extensionProperty.field"),
                  "show-operation-button": ""
                },
                on: {
                  addRow: function($event) {
                    return _vm.addRow("leftPinnedColumns")
                  },
                  removeRow: function($event) {
                    return _vm.removeRow("leftPinnedColumns", index)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("DropDownSelectFilter", {
                      attrs: {
                        single: "",
                        data: _vm.dropList,
                        "auto-data": _vm.searchList,
                        "page-size": _vm.pageSize,
                        "total-row-count": _vm.totalCount,
                        "columns-key": _vm.columnsKey,
                        hidecolumns: _vm.hidecolumns,
                        "default-selected":
                          _vm.defaultFields.leftPinnedColumns[index],
                        placeholder: _vm.$t(
                          "extensionProperty.selectLeftColumn"
                        )
                      },
                      on: {
                        "on-popper-show": _vm.getSearchData,
                        "on-page-change": _vm.getSearchData,
                        "on-input-value-change": _vm.inputSearchData,
                        "on-fkrp-selected": function($event) {
                          return _vm.selectValue(
                            $event,
                            "leftPinnedColumns",
                            index
                          )
                        },
                        "on-clear": function($event) {
                          return _vm.clearValue("leftPinnedColumns", index)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("extensionProperty.rightColumnTable")))
          ]),
          _vm._v(" "),
          _vm._l(_vm.formData.rightPinnedColumns, function(keyInfo, index) {
            return _c(
              "ExtentionRowItem",
              {
                key: index,
                attrs: {
                  "row-index": index,
                  "row-count": _vm.formData.rightPinnedColumns.length,
                  "error-tip": _vm.fieldValidateTip.rightPinnedColumns[index],
                  "row-label": _vm.$t("extensionProperty.field"),
                  "show-operation-button": ""
                },
                on: {
                  addRow: function($event) {
                    return _vm.addRow("rightPinnedColumns")
                  },
                  removeRow: function($event) {
                    return _vm.removeRow("rightPinnedColumns", index)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("DropDownSelectFilter", {
                      attrs: {
                        single: "",
                        data: _vm.dropList,
                        "auto-data": _vm.searchList,
                        "page-size": _vm.pageSize,
                        "total-row-count": _vm.totalCount,
                        "columns-key": _vm.columnsKey,
                        hidecolumns: _vm.hidecolumns,
                        "default-selected":
                          _vm.defaultFields.rightPinnedColumns[index],
                        placeholder: _vm.$t(
                          "extensionProperty.selectRightColumn"
                        )
                      },
                      on: {
                        "on-popper-show": _vm.getSearchData,
                        "on-page-change": _vm.getSearchData,
                        "on-input-value-change": _vm.inputSearchData,
                        "on-fkrp-selected": function($event) {
                          return _vm.selectValue(
                            $event,
                            "rightPinnedColumns",
                            index
                          )
                        },
                        "on-clear": function($event) {
                          return _vm.clearValue("rightPinnedColumns", index)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }