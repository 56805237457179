var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extentionProperty tw-mt-10" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "div",
        { staticClass: "search-pannel" },
        [
          _c("Input", {
            attrs: {
              search: "",
              "enter-button": _vm.$t("tips.find"),
              placeholder: _vm.$t("extensionProperty.enterConfig")
            },
            on: { "on-change": _vm.filterConfig },
            model: {
              value: _vm.configName,
              callback: function($$v) {
                _vm.configName = $$v
              },
              expression: "configName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "menu-list" },
        [
          _vm._l(_vm.configList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "menu",
                class: { active: index === _vm.currentIndex },
                on: {
                  click: function($event) {
                    _vm.scrollIntoView(item, index)
                  }
                }
              },
              [
                _c("span", { staticClass: "config-title" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "extentionProperty-key" }, [
                  _vm._v(_vm._s(item.key === "__root__" ? "" : item.key))
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.configList.length === 0
            ? _c("li", { staticStyle: { "justify-content": "center" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("extensionProperty.noConfiguration")) +
                    "\n      "
                )
              ])
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "middle" },
      [
        _vm._l(_vm.configList, function(item, index) {
          return [
            index === _vm.currentIndex
              ? _c(
                  "div",
                  { key: item.name, staticClass: "item-render-area" },
                  [
                    item.type === "input"
                      ? _c("ExtentionInput", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            option: item,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key]
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "radio"
                      ? _c("ExtentionRadio", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            option: item,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key]
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "select"
                      ? _c("ExtentionSelect", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            item: item,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key]
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "input-group"
                      ? _c("ExtentionInputGroup", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            ),
                            option: item
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "object-group"
                      ? _c("ExtentionObjectGroup", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key],
                            option: item
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "object-value"
                      ? _c("ExtensionObjectValue", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key],
                            option: item
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "options-group"
                      ? _c("ExtentionOptions", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key],
                            option: item
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "keyValue"
                      ? _c("KeyValueItem", {
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            ),
                            option: item
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "custom"
                      ? _c(item.component, {
                          tag: "components",
                          attrs: {
                            id: item.key + "-" + index + "-" + _vm.guid,
                            "default-data": JSON.parse(
                              JSON.stringify(_vm.rootData)
                            )[item.key],
                            option: item,
                            keyForm: _vm.keyForm
                          },
                          on: {
                            dataChange: _vm.rootDataChange,
                            removeOption: _vm.removeOption
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _c("textarea", {
        attrs: { readonly: "" },
        domProps: { value: _vm.formatedRootData }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }