var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "row-label": _vm.$t("extensionProperty.sourceField"),
                "is-required": "",
                "row-label-class": "rowLabel",
                "error-tip": _vm.fieldValidateTips
              }
            },
            [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("DropDownSelectFilter", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        single: "",
                        data: _vm.dropList,
                        "auto-data": _vm.searchList,
                        "page-size": _vm.pageSize,
                        "total-row-count": _vm.totalCount,
                        "columns-key": _vm.columnsKey,
                        hidecolumns: _vm.hidecolumns,
                        placeholder: _vm.$t(
                          "extensionProperty.selectSourceField"
                        ),
                        "default-selected": _vm.defaultFields
                      },
                      on: {
                        "on-popper-show": _vm.getSearchData,
                        "on-page-change": _vm.getSearchData,
                        "on-input-value-change": _vm.inputSearchData,
                        "on-fkrp-selected": function($event) {
                          return _vm.selectValue($event)
                        },
                        "on-clear": _vm.clearTableName
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "row-label": _vm.$t("extensionProperty.correspondence"),
                "is-required": "",
                "row-label-class": "rowLabel"
              }
            },
            [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _vm._l(_vm.formData.list, function(row, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "extensionProperty.enterOptionKey"
                              )
                            },
                            on: { "on-blur": _vm.validCondition },
                            model: {
                              value: row.key,
                              callback: function($$v) {
                                _vm.$set(row, "key", $$v)
                              },
                              expression: "row.key"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "extensionProperty.enterOptionValue"
                              )
                            },
                            on: { "on-blur": _vm.validCondition },
                            model: {
                              value: row.value,
                              callback: function($$v) {
                                _vm.$set(row, "value", $$v)
                              },
                              expression: "row.value"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col btn-group" }, [
                        _vm.formData.list.length - 1 === index
                          ? _c(
                              "button",
                              {
                                staticClass: "operate-button",
                                on: { click: _vm.addRow }
                              },
                              [
                                _c("i", { staticClass: "iconfont" }, [
                                  _vm._v("")
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.list.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass: "operate-button",
                                on: {
                                  click: function($event) {
                                    return _vm.removeRow(index)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "iconfont" }, [
                                  _vm._v("")
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm.conditionValidateTips
                    ? _c("div", { staticClass: "tip" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.conditionValidateTips) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }