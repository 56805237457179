var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extentionObjectGroup" },
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content-row" }, [
        _vm.option.showLabel
          ? _c("div", { staticClass: "left" }, [
              _c("label", [_vm._v(_vm._s(_vm.option.name))]),
              _vm._v(" "),
              _c("label", [_vm._v(_vm._s(_vm.option.key))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          _vm._l(_vm.dataArray, function(data, index) {
            return _c(
              "div",
              { key: index, staticClass: "content" },
              [
                index <= _vm.currentIndex
                  ? _c("ObjectGroupItem", {
                      attrs: {
                        "object-group-index": index,
                        "default-data": _vm.defaultData[index] || {},
                        data: data,
                        option: _vm.option,
                        "show-add-button":
                          _vm.currentIndex === index && _vm.currentIndex !== 9,
                        "show-minus-button":
                          _vm.currentIndex === index && _vm.currentIndex !== 0
                      },
                      on: {
                        objectGroupItemChange: _vm.objectGroupItemChange,
                        addButtonClick: _vm.addButtonClick,
                        minusButtonClick: _vm.minusButtonClick
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }