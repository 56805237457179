var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c("Complexs-dialog", {
        ref: "dialog",
        attrs: {
          treedata: _vm.Tree,
          loading: _vm.loading,
          "tree-loading": _vm.tree_loading,
          "table-loading": _vm.tableLoading,
          "component-data": _vm.componentData,
          "result-data": _vm.resultData
        },
        on: {
          "on-change-tree": _vm.changeTtree,
          "on-click-tab": _vm.clickTab,
          "on-change-page": _vm.changePage,
          "on-change-pageSize": _vm.changePageSize,
          "on-row-dblclick": _vm.rowdbclick,
          "on-row-click": _vm.rowclick,
          "on-change": _vm.inputchange,
          "on-search": _vm.inputsearch,
          "on-checkbox-change": _vm.checkboxChange,
          "on-transfer-two": _vm.transfertwo,
          "on-transfer": _vm.transfer,
          "on-delectli": _vm.deleteLi,
          "on-refresh": _vm.refreshFun,
          "on-saveBtn": _vm.saveBtn,
          "on-deleBtn": _vm.deleBtn
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }