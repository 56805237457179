var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { ref: "sequence" },
    [
      _vm.valueOfId === _vm.$t("tips.summation") ||
      _vm.valueOfId === _vm.$t("tips.total")
        ? [_vm._v(_vm._s(_vm.valueOfId))]
        : _c(
            "span",
            {
              staticStyle: { color: "#0f8ee9" },
              attrs: { "data-target-tag": "rowIndex" }
            },
            [
              _vm._v(
                _vm._s(
                  (_vm.params.data[_vm.AG_SEQUENCE_COLUMN_NAME] &&
                    _vm.params.data[_vm.AG_SEQUENCE_COLUMN_NAME].val) ||
                    "" + parseInt(_vm.params.options.datas.start, 10)
                )
              )
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }