var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.option.components, function(component, index) {
        return [
          !component.isShow ||
          (component.isShow && component.isShow(_vm.formData))
            ? _c("div", { key: index, staticClass: "tw-mt-20 form-group" }, [
                _c("div", { staticClass: "row tw-mt-20" }, [
                  _c("div", { staticClass: "label-column" }, [
                    _c("p", [_vm._v(_vm._s(component.name))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("[" + _vm._s(component.key) + "]：")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-column" }, [
                    _c(
                      "div",
                      { staticClass: "item-row tw-flex-grow tw-flex" },
                      [
                        component.type === "input"
                          ? _c("Input", {
                              directives: [
                                {
                                  name: "input-number",
                                  rawName:
                                    "v-input-number:[component.inputType]",
                                  arg: component.inputType
                                }
                              ],
                              attrs: { placeholder: component.placeholder },
                              on: {
                                input: function($event) {
                                  return _vm.valueChange($event, index)
                                }
                              },
                              model: {
                                value: _vm.formData[component.key],
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, component.key, $$v)
                                },
                                expression: "formData[component.key]"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        component.type === "select"
                          ? _c(
                              "Select",
                              {
                                attrs: { clearable: "" },
                                on: {
                                  "on-change": function($event) {
                                    return _vm.valueChange($event, index)
                                  }
                                },
                                model: {
                                  value: _vm.formData[component.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, component.key, $$v)
                                  },
                                  expression: "formData[component.key]"
                                }
                              },
                              _vm._l(component.selectOptions, function(item) {
                                return _c(
                                  "Option",
                                  {
                                    key: item.value,
                                    attrs: { value: item.value }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.label) +
                                        "\n              "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }