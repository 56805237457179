var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-group-item" }, [
    _c(
      "div",
      { staticClass: "cell" },
      [
        _c("LabelForInput", { attrs: { item: _vm.item } }),
        _vm._v(" "),
        _c("input", {
          ref: "input",
          staticClass: "input",
          attrs: {
            placeholder:
              _vm.$t("extensionProperty.pleaseEnter") +
              "[" +
              _vm.item.name +
              "]" +
              (_vm.item.outputValueType === "Array"
                ? "，" + _vm.$t("extensionProperty.separatedByCommas")
                : ""),
            type: _vm.item.inputType === "Number" ? "number" : "text"
          },
          on: { input: _vm.inputChange, blur: _vm.inputBlur }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.logInfo !== ""
      ? _c("div", { staticClass: "logInfo" }, [
          _vm._v("\n    " + _vm._s(_vm.logInfo) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }