var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ExtentionOptions" },
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("LabelWithInput", {
            attrs: {
              item: _vm.clonameOptions,
              "default-data": _vm.defaultData.colnames
            },
            on: {
              inputValueChange: _vm.inputValueChange,
              inputBlur: _vm.inputBlur
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.defaultData.rules, function(item, i) {
        return _c(
          "div",
          { key: i, staticClass: "itme" },
          _vm._l(_vm.defaultData.colnames.split(","), function(temp, j) {
            return _c("p", { key: j, staticClass: "colnameItem" }, [
              _c("span", [_vm._v(_vm._s(temp))])
            ])
          }),
          0
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }