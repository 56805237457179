var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extentionInputGroup" },
    [
      _c("Description", {
        attrs: { option: _vm.option },
        on: { removeOption: _vm.removeOption }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._l(_vm.option.inputLists, function(item, index) {
            return [
              item.type === "input"
                ? _c("LabelWithInput", {
                    key: index,
                    attrs: {
                      item: item,
                      "default-data":
                        _vm.option.key === "__root__"
                          ? _vm.defaultData[item.key]
                          : _vm.defaultData[_vm.option.key]
                          ? _vm.defaultData[_vm.option.key][item.key]
                          : ""
                    },
                    on: { inputValueChange: _vm.inputGroupValueChange }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "radio"
                ? _c("LabelWithRadio", {
                    key: index,
                    attrs: {
                      item: item,
                      index: index,
                      "default-data": _vm.setDefaultData(_vm.option, item)
                    },
                    on: { radioValueChange: _vm.inputGroupValueChange }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "select"
                ? _c("LabelWithSelect", {
                    key: index,
                    attrs: {
                      item: item,
                      index: index,
                      "default-data": _vm.setDefaultData(_vm.option, item)
                    },
                    on: { selectValueChange: _vm.inputGroupValueChange }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "object-group"
                ? _c("ExtentionObjectGroup", {
                    key: index,
                    attrs: {
                      "show-description": false,
                      option: item,
                      index: index,
                      "default-data":
                        _vm.option.key === "__root__"
                          ? _vm.defaultData[item.key]
                          : (_vm.defaultData[_vm.option.key] || {})[item.key]
                    },
                    on: { dataChange: _vm.objectGroupValueChange }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }