var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { attrs: { title: _vm.params.value || "" } },
    [
      _vm.params.value &&
      ((_vm.params.data &&
        _vm.params.data.ID.val === _vm.$t("tips.summation")) ||
        _vm.params.data.ID.val === _vm.$t("tips.total"))
        ? _c("span", { attrs: { title: _vm.params.value || "" } }, [
            _vm._v(_vm._s(_vm.params.value || ""))
          ])
        : [
            _vm.params.value
              ? _c("i", {
                  class: ["iconfont", _vm.cssFeatures.hover, "iconbj_link"],
                  staticStyle: { color: "#0f8ee9", "font-size": "12px" },
                  attrs: { "data-target-tag": "fkIcon" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { attrs: { title: _vm.params.value || "" } }, [
              _vm._v(_vm._s(_vm.params.value || ""))
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }