var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PassWord" },
    [
      _c("Description", {
        attrs: { option: _vm.option },
        on: { removeOption: _vm.removeOption }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cell" },
        [
          _c("EnumerateRadioItem", {
            attrs: {
              option: _vm.option,
              "break-line": false,
              "default-data": _vm.value
            },
            on: { radioValueChange: _vm.radioValueChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.value
        ? _c(
            "div",
            { staticClass: "cell" },
            [
              _c("LabelWithSelect", {
                attrs: {
                  item: {
                    key: "is_all",
                    name: _vm.$t("extensionProperty.encryptionMethod"),
                    selectOptions: [
                      {
                        text: _vm.$t("extensionProperty.hideAll"),
                        value: true
                      },
                      {
                        text: _vm.$t("extensionProperty.customHide"),
                        value: false
                      }
                    ]
                  },
                  "default-data": _vm.selectValue
                },
                on: { selectValueChange: _vm.selectValueChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value && !_vm.selectValue
        ? _c(
            "div",
            { staticClass: "cell" },
            [
              _c("LabelWithInput", {
                attrs: {
                  "default-data": _vm.password_prefix_digit,
                  item: {
                    key: "password_prefix_digit",
                    name: _vm.$t("extensionProperty.frontHiddenDigits"),
                    type: "input"
                  }
                },
                on: { inputValueChange: _vm.inputValueChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value && !_vm.selectValue
        ? _c(
            "div",
            { staticClass: "cell" },
            [
              _c("LabelWithInput", {
                attrs: {
                  "default-data": _vm.password_suffix_digit,
                  item: {
                    key: "password_suffix_digit",
                    name: _vm.$t("extensionProperty.endHiddenDigits"),
                    type: "input"
                  }
                },
                on: { inputValueChange: _vm.inputValueChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }