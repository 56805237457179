var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "ag-text-component",
      attrs: { title: _vm.params.value || "" }
    },
    [
      [
        _vm.width
          ? _c(
              _vm.tag,
              {
                tag: "component",
                staticClass: "one-line-flow ag-text-component-content",
                style: "width:" + _vm.width
              },
              [
                _c(
                  "span",
                  { attrs: { title: "" + (_vm.params.value || "") } },
                  [_vm._v(_vm._s(_vm.params.value || ""))]
                )
              ]
            )
          : _c(
              _vm.tag,
              {
                tag: "component",
                staticClass: "one-line-flow ag-text-component-content"
              },
              [
                _c(
                  "span",
                  { attrs: { title: "" + (_vm.params.value || "") } },
                  [_vm._v(_vm._s(_vm.params.value || ""))]
                )
              ]
            )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }