var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RadioGroup",
    _vm._g(
      _vm._b(
        {
          on: { "on-change": _vm.radioChange },
          model: {
            value: _vm.formData.defaultValue,
            callback: function($$v) {
              _vm.$set(_vm.formData, "defaultValue", $$v)
            },
            expression: "formData.defaultValue"
          }
        },
        "RadioGroup",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.formData.selectOptions, function(item) {
      return _c("Radio", { key: item.label, attrs: { label: item.label } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }