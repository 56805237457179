var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "tableContainer",
      staticClass: "ag-grid-table-wrap",
      style: _vm.tableStyle
    },
    [
      _vm.columns.length > 0
        ? _c("ag-grid-vue", {
            ref: "table",
            staticClass: "ag-grid-table ag-theme-balham",
            attrs: { gridOptions: _vm.realAgOptions }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }