var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "extentionInput",
      staticClass: "extentionInput",
      on: { dblclick: _vm.popUp }
    },
    [
      _c("Input", {
        ref: "textarea",
        attrs: {
          type: "textarea",
          rows: _vm.rows,
          placeholder: _vm.placeholder,
          readonly: "",
          disabled: _vm.webconf.disabled
        },
        on: { "on-keydown": _vm.onKeydown }
      }),
      _vm._v(" "),
      _c("Icon", { attrs: { type: "md-hammer" }, on: { click: _vm.popUp } }),
      _vm._v(" "),
      !_vm.webconf.disabled
        ? _c("Icon", {
            staticClass: "R3iconbj_delete",
            attrs: { type: "iconbj_delete" },
            on: { click: _vm.deleteValue }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "div",
            [
              _c(
                "Modal",
                {
                  ref: "extentionInputModal",
                  attrs: {
                    value: _vm.showModal,
                    closable: true,
                    mask: true,
                    "mask-closable": false,
                    title: _vm.$t("extensionProperty.extendAttrConfig"),
                    width: 80
                  },
                  on: { "on-ok": _vm.onOk, "on-cancel": _vm.onCancel }
                },
                [
                  _vm.showModal
                    ? _c("ExtensionPropertyWrap", {
                        attrs: {
                          "default-data": _vm.displayData,
                          "real-data": _vm.transformedData,
                          formInfo: _vm.formInfo,
                          tableName: _vm.tableName,
                          extentionConfig: _vm.extentionConfig,
                          webconf: _vm.webconf
                        },
                        on: { valueChange: _vm.valueChange }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }