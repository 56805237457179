var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Select",
    _vm._g(
      _vm._b(
        {
          attrs: { placeholder: _vm.formData.placeholder, clearable: "" },
          model: {
            value: _vm.formData.defaultValue,
            callback: function($$v) {
              _vm.$set(_vm.formData, "defaultValue", $$v)
            },
            expression: "formData.defaultValue"
          }
        },
        "Select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.formData.selectOptions, function(item) {
      return _c("Option", { key: item.value, attrs: { value: item.value } }, [
        _vm._v("\n    " + _vm._s(item.label) + "\n  ")
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }