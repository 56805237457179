import { render, staticRenderFns } from "./ExtensionObjectValue.vue?vue&type=template&id=00b13d5f&scoped=true&"
import script from "./ExtensionObjectValue.vue?vue&type=script&lang=js&"
export * from "./ExtensionObjectValue.vue?vue&type=script&lang=js&"
import style0 from "./ExtensionObjectValue.vue?vue&type=style&index=0&id=00b13d5f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00b13d5f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chenzihao/code/workspace/arkui-bus/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00b13d5f')) {
      api.createRecord('00b13d5f', component.options)
    } else {
      api.reload('00b13d5f', component.options)
    }
    module.hot.accept("./ExtensionObjectValue.vue?vue&type=template&id=00b13d5f&scoped=true&", function () {
      api.rerender('00b13d5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/extension-property/ExtensionObjectValue.vue"
export default component.exports