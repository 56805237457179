var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        _vm._l(_vm.listConfig, function(group, index) {
          return _c(
            "ExtentionRowItem",
            {
              key: index,
              attrs: {
                "row-index": index,
                "row-count": _vm.listConfig.length,
                "row-label": "" + _vm.$t("tips.button") + (index + 1),
                "is-required": "",
                "row-label-class": "rowLabel",
                "show-operation-button": "",
                "error-tip": _vm.rowValidateTips[index]
              },
              on: {
                addRow: _vm.addRow,
                removeRow: function($event) {
                  return _vm.removeRow(index)
                }
              }
            },
            [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("Input", {
                      attrs: {
                        placeholder: _vm.$t("extensionProperty.buttonField")
                      },
                      on: {
                        "on-blur": function($event) {
                          return _vm.validateKey(index)
                        }
                      },
                      model: {
                        value: group.input,
                        callback: function($$v) {
                          _vm.$set(group, "input", $$v)
                        },
                        expression: "group.input"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.inputValidateTips[index]
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.inputValidateTips[index]) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                group.input
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("R3Select", {
                            attrs: { options: group.selectConfig },
                            on: {
                              change: function($event) {
                                return _vm.getSelect($event, index)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "extensionProperty.correspondingValue"
                              )
                            },
                            on: {
                              "on-blur": function($event) {
                                return _vm.validateKey(index)
                              }
                            },
                            model: {
                              value: group.value,
                              callback: function($$v) {
                                _vm.$set(group, "value", $$v)
                              },
                              expression: "group.value"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "extensionProperty.optionalPrompt"
                              )
                            },
                            model: {
                              value: group.desc,
                              callback: function($$v) {
                                _vm.$set(group, "desc", $$v)
                              },
                              expression: "group.desc"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }