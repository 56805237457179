var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "labelWithObjectGroup" },
    [
      _vm._l(_vm.option.objectInfo, function(item, index) {
        return [
          item.type === "input"
            ? _c("LabelWithInput", {
                key: index,
                attrs: {
                  item: item,
                  "default-data": _vm.defaultData[item.key]
                },
                on: { inputValueChange: _vm.objectGroupValueChange }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "radio"
            ? _c("LabelWithRadio", {
                key: index,
                attrs: {
                  item: item,
                  "default-data": _vm.defaultData[item.key]
                },
                on: { radioValueChange: _vm.objectGroupValueChange }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "select"
            ? _c("LabelWithSelect", {
                key: index,
                attrs: {
                  item: item,
                  "default-data": _vm.defaultData[item.key]
                },
                on: { selectValueChange: _vm.objectGroupValueChange }
              })
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _vm.showAddButton && !_vm.currentDataIsEmpty
        ? _c(
            "button",
            {
              staticClass: "operate-button",
              on: { click: _vm.addButtonClick }
            },
            [_vm._v("\n    +\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showMinusButton
        ? _c(
            "button",
            {
              staticClass: "operate-button",
              on: { click: _vm.minusButtonClick }
            },
            [_vm._v("\n    -\n  ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }