var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.params.column.colDef.checkboxSelection
    ? _c(
        "span",
        {
          staticClass: "ag-header-index",
          attrs: { title: _vm.params.headerName || _vm.params.displayName }
        },
        [_vm._v(_vm._s(_vm.params.headerName || _vm.params.displayName))]
      )
    : _c(
        "div",
        {
          staticClass: "ag-cell-label-container",
          on: {
            mouseenter: _vm.containerMouseenter,
            mouseleave: _vm.containeroMouseleave,
            click: _vm.headerClick
          }
        },
        [
          _c(
            "span",
            {
              ref: "eMenu",
              staticClass: "ag-header-icon ag-header-cell-menu-button",
              style: _vm.eMenuStyle,
              on: { click: _vm.menuClick }
            },
            [_c("span", { staticClass: "ag-icon ag-icon-menu" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "eLabel",
              staticClass: "ag-header-cell-label",
              style: _vm.eLabelStyle,
              attrs: { role: "presentation" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "one-line-flow ag-header-wrap",
                  class: [
                    _vm.params.column.colDef.thAlign
                      ? "text-" + _vm.params.column.colDef.thAlign
                      : ""
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      ref: "eText",
                      staticClass: "ag-header-cell-text",
                      attrs: {
                        role: "columnheader",
                        title: _vm.params.headerName || _vm.params.displayName
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.params.headerName || _vm.params.displayName
                          ) +
                          "\n        "
                      ),
                      _vm.params.column.colDef.comment
                        ? _c("i", {
                            class: [
                              "iconfont",
                              "comment",
                              "iconios-information-circle-outline",
                              _vm.cssFeatures.hover
                            ],
                            staticStyle: { color: "orangered" },
                            on: {
                              mouseenter: _vm.commentMouseenter,
                              mouseleave: _vm.commentMouseleave
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      ref: "eSortAsc",
                      class: [
                        "ag-header-sort",
                        "ag-header-icon",
                        "ag-sort-ascending-icon",
                        _vm.params.column.colDef.isorder &&
                        _vm.params.column.colDef.sort === "asc"
                          ? ""
                          : "ag-hidden"
                      ]
                    },
                    [
                      _c("span", {
                        staticClass:
                          "ag-icon ag-icon-asc ${cssFeatures.hover} trigger-sorting"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      ref: "eSortDesc",
                      class: [
                        "ag-header-sort",
                        "ag-header-icon",
                        "ag-sort-descending-icon",
                        _vm.params.column.colDef.isorder &&
                        _vm.params.column.colDef.sort === "desc"
                          ? ""
                          : "ag-hidden"
                      ]
                    },
                    [
                      _c("span", {
                        staticClass:
                          "ag-icon ag-icon-desc ${cssFeatures.hover} trigger-sorting"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      ref: "eSortNone",
                      class: [
                        "ag-header-sort",
                        "ag-header-icon",
                        "ag-sort-none-icon",
                        !_vm.params.column.colDef.sort &&
                        _vm.params.column.colDef.isorder
                          ? ""
                          : "ag-hidden"
                      ]
                    },
                    [
                      _c("span", {
                        staticClass:
                          "ag-icon ag-icon-none ${cssFeatures.hover} trigger-sorting"
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }