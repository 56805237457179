var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "ExtentionRowItem",
            {
              attrs: {
                "is-required": "",
                "row-label": _vm.$t("extensionProperty.assignValues"),
                "error-tip": _vm.fieldValidateTip
              }
            },
            [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("DropDownSelectFilter", {
                    attrs: {
                      single: false,
                      data: _vm.dropList,
                      "auto-data": _vm.searchList,
                      "page-size": _vm.pageSize,
                      "total-row-count": _vm.totalCount,
                      "columns-key": _vm.columnsKey,
                      hidecolumns: _vm.hidecolumns,
                      placeholder: _vm.$t("extensionProperty.selectKnownField"),
                      "default-selected": _vm.defaultFields
                    },
                    on: {
                      "on-popper-show": _vm.getSearchData,
                      "on-page-change": _vm.getSearchData,
                      "on-input-value-change": _vm.inputSearchData,
                      "on-fkrp-selected": function($event) {
                        return _vm.selectTable($event)
                      },
                      "on-clear": _vm.clearTableName
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("Input", {
                    attrs: {
                      placeholder: _vm.$t("extensionProperty.enterApi")
                    },
                    on: { "on-blur": _vm.validateField },
                    model: {
                      value: _vm.formData.url,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "url", $$v)
                      },
                      expression: "formData.url"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }