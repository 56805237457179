var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ag-grid-table",
        _vm._g(
          {
            ref: "agGridTable",
            class: [_vm.border ? "ag-border" : ""],
            attrs: {
              options: _vm.filteredOption,
              height: _vm.height,
              columns: _vm.columns,
              data: _vm.data,
              renderParams: _vm.renderFn,
              mode: _vm.mode
            },
            on: {
              "on-selection-change": _vm.tableSelectedChange,
              "on-sort-change": _vm.tableSortChange,
              "on-row-dblclick": _vm.tableRowDbclick,
              "grid-ready": _vm.tableGridReady
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }