var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDescription
        ? _c("Description", {
            attrs: { option: _vm.option },
            on: { removeOption: _vm.removeOption }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.formData, function(row, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-group" },
          [
            _c(
              "ExtentionRowItem",
              {
                attrs: {
                  "row-index": index,
                  "row-count": _vm.formData.length,
                  "row-label":
                    "" + _vm.$t("extensionProperty.condition") + (index + 1),
                  "show-operation-button": "",
                  "row-label-class": "rowLabel",
                  "is-required": "",
                  "error-tip": _vm.validateTips[index]
                },
                on: {
                  addRow: _vm.addRow,
                  removeRow: function($event) {
                    return _vm.removeRow(index)
                  }
                }
              },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "row " }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("extensionProperty.ifFieldOnMainTable")
                            ) + "："
                          )
                        ]),
                        _vm._v(" "),
                        _c("R3Radio", {
                          attrs: { options: row.radioConfig },
                          on: {
                            change: function($event) {
                              return _vm.getRadioValue($event, index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("Input", {
                          attrs: {
                            placeholder: _vm.$t(
                              "extensionProperty.knownConditionField"
                            )
                          },
                          on: { "on-blur": _vm.validateForm },
                          model: {
                            value: row.srccol,
                            callback: function($$v) {
                              _vm.$set(row, "srccol", $$v)
                            },
                            expression: "row.srccol"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("Input", {
                          attrs: {
                            placeholder: _vm.$t(
                              "extensionProperty.filteredFieldInForeignKey"
                            )
                          },
                          on: { "on-blur": _vm.validateForm },
                          model: {
                            value: row.fixcolumn,
                            callback: function($$v) {
                              _vm.$set(row, "fixcolumn", $$v)
                            },
                            expression: "row.fixcolumn"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }