var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-group-item" }, [
    _c(
      "div",
      { staticClass: "cell" },
      [
        _c("LabelForInput", { attrs: { item: _vm.item } }),
        _vm._v(" "),
        _c(
          "select",
          {
            ref: "select",
            staticClass: "select",
            on: { change: _vm.selectValueChange }
          },
          _vm._l(_vm.item.selectOptions, function(o, i) {
            return _c("option", { key: i, domProps: { value: o.value } }, [
              _vm._v("\n        " + _vm._s(o.text) + "\n      ")
            ])
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }