<template>
  <div class="bindKey">
    <Description
      :option="option"
      @removeOption="removeOption"
    />

    <div
      v-for="(item,index) in resultList"
      :key="index"
      class="tabContent"
    >
      <div class="label-input">
        <div class="required-item ml-5">
          {{$t('extensionProperty.displayAreaField')}}：
        </div>
      </div>
      <div class="target-key mb-10 ml-10">
        <DropDownSelectFilter
          single
          :data="keyList"
          :auto-data="searchKeyList"
          :page-size="pageSize"
          :total-row-count="totalCount"
          :default-selected="defaultSelected[index].target"
          :placeholder="$t('extensionProperty.enterNameInTable')"
          :columns-key="targetColumnsKey"
          @on-popper-show="getKeys($event, 'target',{
            tableName:'AD_COLUMN'
          })"
          @on-page-change="getKeys($event, 'target',{
            tableName:'AD_COLUMN'
          })"
          @on-input-value-change="getSearchKeys($event, 'target',{
            tableName:'AD_COLUMN',
            groupIndex: index
          })"
          @on-fkrp-selected="handlerSelected(index, 'target', '', $event)"
          @on-clear="handleClear(index, 'target', '', $event)"
        />
      </div>

      <!-- blank -->
      <p class="blank" />

      <SlickList
        v-model="item.source"
        axis="y"
        :lock-to-container-edges="true"
        :press-delay="600"
        helper-class="r3-slick"
      >
        <SlickItem
          v-for="(temp,j) in item.source"
          :key="j"
          :index="j"
        >
          <div
            class="colnameContent"
            @mouseenter="handleEnter"
            @mouseleave="handleLeave"
          >
            <div class="colname">
              <p class="required-item ml-5">
                {{$t('extensionProperty.sourceField')}}:
              </p>
              <DropDownSelectFilter
                single
                isBackRowItem
                :data="keyList"
                :auto-data="searchKeyList"
                :page-size="pageSize"
                :total-row-count="totalCount"
                :default-selected="defaultSelected[index].source[j][0]"
                :columns-key="targetColumnsKey"
                :placeholder="$t('extensionProperty.enterNameInTable')"
                @on-popper-show="getKeys($event, 'source',{
                  tableName:'AD_COLUMN'
                })"
                @on-page-change="getKeys($event, 'source',{
                  tableName:'AD_COLUMN'
                })"
                @on-input-value-change="getSearchKeys($event, 'source',{
                  tableName:'AD_COLUMN',
                  groupIndex: index,
                  rowIndex: j
                })"
                @on-fkrp-selected="handlerSelected(index, 'source', j , $event, 0)"
                @on-clear="handleClear(index, 'source', j, 0)"
              />
            </div>
            <div class="colname">
              <p class="required-item ml-5">
                {{$t('extensionProperty.sourceFieldOptionGroup')}}:
              </p>
              <DropDownSelectFilter
                single
                isBackRowItem
                :data="keyList"
                :auto-data="searchKeyList"
                :page-size="pageSize"
                :total-row-count="totalCount"
                :default-selected="defaultSelected[index].source[j][1]"
                :columns-key="sourceColumnsKey"
                :placeholder="$t('extensionProperty.enterName')"
                @on-popper-show="getKeys($event, 'source',{
                  tableName:'AD_LIMITVALUE_GROUP',
                  deleteTableId: true
                })"
                @on-page-change="getKeys($event, 'source',{
                  tableName:'AD_LIMITVALUE_GROUP',
                  deleteTableId: true
                })"
                @on-input-value-change="getSearchKeys($event, 'source', {
                  tableName:'AD_LIMITVALUE_GROUP',
                  deleteTableId: true,
                  groupIndex: index,
                  rowIndex: j
                })"
                @on-fkrp-selected="handlerSelected(index, 'source', j , $event, 1)"
                @on-clear="handleClear(index, 'source', j, 1)"
              />
            </div>

            <!-- 增加字段按钮 -->
            <div class="oprate">
              <p />
              <button
                v-if="item.source.length - 1 === Number(j)"
                class="operate-button"
                @click="addColname(item.source,index)"
              >
                <i class="iconfont">&#xec3f;</i>
              </button>
              <button
                v-if="item.source.length > 1"
                class="operate-button"
                @click="deleteColname(item.source,index,j)"
              >
                <i class="iconfont">&#xed15;</i>
              </button>
            </div>
          </div>
        </SlickItem>
      </SlickList>

      <!-- 用于增加组配置的按钮 -->
      <button
        v-if="resultList.length - 1 === index"
        class="operate-button ml-10 mb-10"
        @click="addButtonClick"
      >
        <i class="iconfont">&#xec3f;</i>
      </button>
      <button
        v-if="resultList.length > 1"
        class="operate-button ml-10 mb-10"
        @click="removeButtonClick(index)"
      >
        <i class="iconfont">&#xed15;</i>
      </button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import i18n from '../../../utils/i18n'
import { SlickList, SlickItem } from 'vue-slicksort';
import Description from '../description';
import { urlSearchParams } from '../../../utils/http';

const GROUP_CONSTRUCTOR = {
  target: {
    col_id: '',
  },
  source: [
    {
      col_id: '',
      label: '',
    }
  ]
};

export default {
  name: 'BindKey',

  inject: ['network'],

  props: {
    option: {
      type: Object,
      default: () => ({})
    },
    defaultData: {
      type: [Object, Array],
      default: () => ([])
    }
  },

  components: {
    Description,
    SlickList,
    SlickItem,
  },

  data() {
    return {
      resultList: [JSON.parse(JSON.stringify(GROUP_CONSTRUCTOR))],
      keyList: {},
      searchKeyList: [],
      totalCount: 0,
      pageSize: 10,
      targetColumnsKey: ['DBNAME'],
      sourceColumnsKey: ['NAME'],
      defaultSelected: [{
        target: [],
        source: [[[], []]]
      }]
    };
  },

  watch: {
    resultList: {
      handler() {
        this.syncData();
      },
      deep: true
    }
  },

  async created() {
    this.init()
    this.setHover();
  },

  methods: {
    // 回填数据
    async init() {
      const newData = JSON.parse(JSON.stringify(this.defaultData));
      if (this.defaultData && this.defaultData.length > 0) {
        const idList = [] // 字段id
        const groupIdList = []// 字段选项组id

        // 取出所有id值
        // 加 = 号可以精确查找
        this.defaultData.forEach((group) => {
          idList.push(`=${group.target.col_id}`)
          group.source.forEach((row) => {
            idList.push(`=${row.col_id}`)
            groupIdList.push(`=${row.label}`)
          })
        })

        // 找回字段信息
        let searchdata = {
          table: 'AD_COLUMN',
          startindex: 0,
          range: idList.length,
          fixedcolumns: {
            ISACTIVE: ['=Y'],
            ID: idList,
            AD_TABLE_ID: [this._table_id_],
          },
          column_include_uicontroller: true,
          isolr: false
        }
        if (!this._table_id_) {
          delete searchdata.fixedcolumns.AD_TABLE_ID;
        }

        const idResult = (await this.requestKeysData(searchdata)).row || [];

        // 找回字段选项组信息
        searchdata = {
          table: 'AD_LIMITVALUE_GROUP',
          startindex: 0,
          range: groupIdList.length,
          fixedcolumns: {
            ISACTIVE: ['=Y'],
            ID: groupIdList,
          },
          column_include_uicontroller: true,
          isolr: false
        }

        const groupResult = (await this.requestKeysData(searchdata)).row || [];

        // 回填默认值
        const tempResult = []
        this.defaultData.forEach((group, groupIndex) => {
          tempResult.push({
            target: [],
            source: [[[], []]]
          })
          const targetField = idResult.find(item => item.ID.val === group.target.col_id)
          tempResult[groupIndex].target = [{
            ID: group.target.col_id,
            Label: targetField.DBNAME.val
          }]

          group.source.forEach((row, rowIndex) => {
            tempResult[groupIndex].source[rowIndex] = [[], []]

            const sourceField = idResult.find(item => item.ID.val === row.col_id)
            const sourceGroupField = groupResult.find(item => item.ID.val === row.label)

            tempResult[groupIndex].source[rowIndex][0] = [{
              ID: row.col_id,
              Label: sourceField.DBNAME.val
            }]
            tempResult[groupIndex].source[rowIndex][1] = [{
              ID: row.label,
              Label: sourceGroupField.NAME.val
            }]
          })
        })
        this.defaultSelected = tempResult
        this.resultList = JSON.parse(JSON.stringify(newData))
      } else {
        this.resultList = [JSON.parse(JSON.stringify(GROUP_CONSTRUCTOR))]
      }
    },

    // 设置悬浮
    setHover() {
      // 通过hook监听组件销毁钩子函数，并取消监听事件
      this.dom = document.createElement('div');
      this.dom.setAttribute('id', 'drag-tip');
      this.dom.innerText = this.$t('extensionProperty.dragSort');
      document.body.appendChild(this.dom);

      window.addEventListener('mousemove', this.setPos);

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('mousemove', this.setPos);
        if (this.dom) {
          document.body.removeChild(this.dom);
        }
      });
    },

    // 设置提示位置
    setPos(e) {
      this.tipStyle = `left: ${e.clientX + 20}px;top:${e.clientY + 20}px;`;
      this.dom.style = this.tipStyle;
    },

    // 鼠标移入时显示提示
    handleEnter() {
      this.dom.classList.add('showTip');
    },

    // 鼠标移入时隐藏提示
    handleLeave() {
      this.dom.classList.remove('showTip');
    },

    removeOption(keyArray) { // 清楚整个配置数据
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit('removeOption', keyArray || []);
    },

    addButtonClick() { // 新增tab配置
      const tab = JSON.parse(JSON.stringify(GROUP_CONSTRUCTOR));
      this.resultList.push(tab);

      this.defaultSelected.push({
        target: [],
        source: [[[], []]]
      })
    },
    removeButtonClick(index) {
      this.resultList.splice(index, 1);
      this.defaultSelected.splice(index, 1)
    },
    addColname(item, groupIndex) { // 新增字段配置
      item.push({
        col_id: '',
        label: '',
      });
      this.defaultSelected[groupIndex].source.push([[], []])
    },
    deleteColname(item, groupIndex, rowIndex) { // 删除字段配置
      item.splice(rowIndex, 1);
      this.defaultSelected[groupIndex].source.splice(rowIndex, 1)
    },


    // 查询key
    async getKeys(page, key, options) {
      const { tableName, deleteTableId } = options;
      let startindex = 0;
      if (typeof page === 'number') {
        startindex = (page - 1) * this.pageSize;
      }

      const { itemId } = this.$route.params;
      const searchdata = {
        table: tableName,
        startindex,
        range: this.pageSize,
        fixedcolumns: {
          AD_TABLE_ID: [itemId],
        },
        column_include_uicontroller: true,
        isolr: false
      };

      if (itemId === 'New' || deleteTableId) {
        delete searchdata.fixedcolumns.AD_TABLE_ID;
      }

      this.keyList = await this.requestKeysData(searchdata);
      this.totalCount = this.keyList.totalRowCount;
    },

    // 模糊查询
    async getSearchKeys(value, key, options) {
      const {
        tableName, deleteTableId
      } = options;
      if (value === '') {
        this.searchKeyList = [];
        return;
      }

      // // 对输入值进行赋值
      // if (key === 'target') {
      //   // 展示字段
      //   this.resultList[groupIndex][key].col_id = value;
      // } else if (deleteTableId) {
      //   // 如果有deleteTableId说明是选项组
      //   this.resultList[groupIndex][key][rowIndex].label = value;
      // } else {
      //   // 来源字段
      //   this.resultList[groupIndex][key][rowIndex].col_id = value;
      // }

      const { itemId } = this.$route.params;
      const searchdata = {
        table: tableName,
        startindex: 0,
        fixedcolumns: {
          AD_TABLE_ID: [itemId],
          ISACTIVE: ['=Y'],
        },
        column_include_uicontroller: true,
        isolr: false
      };

      if (tableName === 'AD_COLUMN') {
        searchdata.fixedcolumns.DBNAME = value;
      } else {
        searchdata.fixedcolumns.NAME = value;
      }

      if (itemId === 'New' || deleteTableId) {
        delete searchdata.fixedcolumns.AD_TABLE_ID;
      }

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          const result = (await this.requestKeysData(searchdata)).row || [];
          this.searchKeyList = result.map((keyObj) => {
            Object.keys(keyObj).forEach((key) => {
              keyObj[key] = keyObj[key].val;
            });
            return keyObj;
          });
        }, 200);
      } else {
        this.timer = setTimeout(async () => {
          const result = (await this.requestKeysData(searchdata)).row || [];
          this.searchKeyList = result.map((keyObj) => {
            Object.keys(keyObj).forEach((key) => {
              keyObj[key] = keyObj[key].val;
            });
            return keyObj;
          });
        }, 200);
      }
    },

    // 获取字段列表
    async requestKeysData(searchdata) {
      return new Promise((resolve) => {
        this.network
          .post('/p/cs/QueryList', urlSearchParams({ searchdata }))
          .then((res) => {
            if (res.data.code === 0) {
              const hideColumns = ['ORDERNO', 'MASK', 'AD_TABLE_ID', 'AD_VERSION_ID', 'ISORDER', 'ISACTIVE', 'ISAGFILTER', 'AGFILTER', 'ISINDEXED', 'OBTAINMANNER', 'REF_COLUMN_ID', 'FKDISPLAY', 'SEARCHMODEL', 'ISREMOTE', 'DISPLAYTYPE', 'COMMENTSTP', 'MODIFIERID', 'MODIFIEDDATE'];
              const tabth = res.data.data.tabth;
              const row = res.data.data.row;
              for (let i = Math.max(tabth.length - 1, 0); i >= 0; i--) {
                const item = tabth[i];
                // 让输入框显示 表内名称 字段
                if (item.colname === 'DBNAME') {
                  item.isak = true;
                } else {
                  item.isak = false;
                }

                // 隐藏table列
                if (hideColumns.includes(item.colname)) {
                  tabth.splice(i, 1);
                }
              }

              row.forEach((item) => {
                // item.AD_LIMITVALUE_GROUP_ID.val = item.AD_LIMITVALUE_GROUP_ID.colid;
                for (const key in item) {
                  // 隐藏模糊结果列
                  if (hideColumns.includes(key)) {
                    delete item[key];
                  }
                }
              });
              resolve(res.data.data);
            } else {
              resolve({});
            }
          }).catch(() => {
            resolve({});
          });
      });
    },

    // 获取选中字段
    handlerSelected(groupIndex, key, rowIndex, value, colIndex) {
      // 选中目标字段值的情况
      if (key === 'target') {
        const selectedObj = {
          col_id: value[0].ID,
          label: value[0].Label,
        };
        this.resultList[groupIndex][key] = selectedObj;
        this.defaultSelected[groupIndex][key] = value
      }
      if (key === 'source') {
        value[0].Label = value[0].Label ? value[0].Label : value[0].rowItem.NAME.val;
        if (colIndex === 0) {
          // 设置来源字段
          const selectedObj = {
            col_id: value[0].ID,
          };
          const row = this.resultList[groupIndex][key][rowIndex] || GROUP_CONSTRUCTOR.source; // 第n组第n行
          this.defaultSelected[groupIndex][key][rowIndex][0] = value

          this.$set(this.resultList[groupIndex][key], rowIndex, Object.assign(row, selectedObj));
        } else {
          // 设置来源字段选项组
          const selectedObj = {
            label: value[0].ID,
          };

          const row = this.resultList[groupIndex][key][rowIndex] || GROUP_CONSTRUCTOR.source; // 第n组第n行
          this.defaultSelected[groupIndex][key][rowIndex][1] = value;
          this.$set(this.resultList[groupIndex][key], rowIndex, Object.assign(row, selectedObj));
        }
      }
    },

    // 设置展示字段
    setDisplayData(originData) {
      const cacheData = JSON.parse(JSON.stringify(originData));
      for (let i = Math.max(cacheData.length - 1, 0); i >= 0; i--) {
        const group = cacheData[i];
        delete group.target.defaultselected;
        delete group.target.label;
        for (let j = Math.max(group.source.length - 1, 0); j >= 0; j--) {
          const row = group.source[j];
          delete row.defaultselected;
          // 删除无效来源字段
          if (!row.col_id || !row.label) {
            group.source.splice(j, 1);
          }
        }
        // 删除无效字段组配置
        if ((!group.target.col_id) || group.source.length === 0) {
          cacheData.splice(i, 1);
        }
      }

      return cacheData;
    },

    // 同步数据到父组件
    syncData() {
      const cacheData = JSON.parse(JSON.stringify(this.resultList));

      const displayData = this.setDisplayData(cacheData);
      if (displayData.length === 0) {
        this.$emit('dataChange', { key: this.option.key, value: '' });
      } else {
        this.$emit('dataChange', { key: this.option.key, value: displayData });
      }
    },

    // 清空下拉所选
    handleClear(groupIndex, key, rowIndex, coulmnIndex) {
      if (key === 'target') {
        this.resultList[groupIndex][key] = {};
      } else if (coulmnIndex === 0) {
        // 清空来源字段
        this.resultList[groupIndex][key][rowIndex].col_id = '';
      } else {
        // 清空字段选项组
        this.resultList[groupIndex][key][rowIndex].label = '';
      }
    },
  },

  beforeCreate() {
    this.$t = i18n.t.bind(i18n)
  },
};
</script>

<style lang="scss" scoped>
.bindKey {
  .ml-5 {
    margin-left: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .required-item {
    position: relative;
    &::before {
      content: '*';
      color: red;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -6px;
    }
  }

  .target-key {
    display: inline-block;
    width: 240px;
  }
  .tab-label-name {
    width: 240px;
  }
  .tabContent {
    border: 1px solid #d3d3d3;
    position: relative;
    margin-bottom: 10px;

    .label-input {
      display: flex;
      align-items: center;
      margin: 10px;

      > span {
        display: inline-block;
        width: 100px;
        text-align: right;
      }
    }

    .blank {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #d3d3d3;
      left: 0;
    }

    .colnameContent {
      display: flex;
      padding: 10px;
      cursor: move;

      > div {
        flex: 1;
        margin-right: 10px;

        > p {
          height: 12px;
          margin-bottom: 4px;
        }

        &.colname {
          flex: 2;
        }

        &.oprate {
          width: 50px;
          flex: none;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .operate-button {
    background-color: transparent;
    outline: none;
    font-size: 16px;
    // padding: 5px;
    border: 1px solid lightgrey;
    width: 20px;
    display: inline-block;
    height: 20px;
    line-height: -1px;
    border-radius: 50%;
    color: grey;
  }
  .operate-button:hover {
    color: #000;
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>

<style lang="less">
#drag-tip {
  display: inline-block;
  padding: 4px 8px;
  box-shadow: 0px 2px 8px rgba(136, 136, 136, 0.4);
  background: #f4f4f4;
  font-size: 12px;
  position: fixed;
  z-index: 3000;
  opacity: 0;
  transition: opacity 0.3s;
}
.showTip {
  opacity: 1 !important;
}

.r3-slick {
  z-index: 99999;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(136, 136, 136, 0.4);

  .ml-5 {
    margin-left: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .required-item {
    position: relative;
    &::before {
      content: '*';
      color: red;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -6px;
    }
  }

  .target-key {
    display: inline-block;
    width: 240px;
  }
  .tab-label-name {
    width: 240px;
  }
  .tabContent {
    border: 1px solid #d3d3d3;
    position: relative;
    margin-bottom: 10px;

    .label-input {
      display: flex;
      align-items: center;
      margin: 10px;

      > span {
        display: inline-block;
        width: 100px;
        text-align: right;
      }
    }

    .blank {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #d3d3d3;
      left: 0;
    }
  }

  .colnameContent {
    display: flex;
    padding: 10px;
    cursor: move;
    pointer-events: auto !important;

    > div {
      flex: 1;
      margin-right: 10px;

      > p {
        height: 12px;
        margin-bottom: 4px;
      }

      &.colname {
        flex: 2;
      }

      &.oprate {
        width: 50px;
        flex: none;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .operate-button {
    background-color: transparent;
    outline: none;
    font-size: 16px;
    // padding: 5px;
    border: 1px solid lightgrey;
    width: 20px;
    display: inline-block;
    height: 20px;
    line-height: -1px;
    border-radius: 50%;
    color: grey;
  }
  .operate-button:hover {
    color: #000;
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
